.overlay {
	z-index: 999;
}

.timeTabSelected {
	padding: 4px 16px;
	border: 1px solid #288ea5;
	background: #e3f3f6;
	margin: 0 8px;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.75px;
	color: #288ea5;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: center;
	align-self: center;
	cursor: pointer;
}

.productGraphContainer {
	border: 1px solid #ebebeb;
	border-radius: 16px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	height: 384px;
}

.productWeight {
	margin-top: 4px;
	margin-bottom: 4px;
	color: #656565;
	font-size: 12px;
}

.productPrice {
	font-size: 14px;
	color: black;
}

.slideButton {
	width: 40px !important;
	height: 40px !important;
	position: absolute;
	top: 68px;
	padding: 0 !important;
	background: rgba(255, 255, 255, 0.6) !important;
	backdrop-filter: blur(10px);
}

.slideButtonLeft {
	left: 8px;
}

.slideButtonRight {
	right: 8px;
}

.productsContainer {
	display: flex;
	overflow: hidden;
	width: 100%;
	border-radius: 10px;
}

.productItemSpinnerContainer {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	height: 176px;
}

.emptyGraph {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
	height: 100%;
}

.productItemContainer {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	width: 180px;
	margin: 0 32px;
}

.productItemContainer:first-of-type {
	margin-right: 32px;
	margin-left: 0;
}

.productItemContainer:last-of-type {
	margin-right: 0px;
	margin-left: 32px;
}

.productImageContainer {
	border-radius: 4px;
	overflow: hidden;
	width: 176px;
	height: 176px;
	outline: none;
}

.productImage {
	width: 176px;
	height: 176px;
	object-fit: contain;
}

.productName {
	margin-top: 16px;
	font-size: 16px;
	font-weight: bold;
	color: black;
}

.productViewButton {
	cursor: pointer;
	margin-left: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

.timeRange {
	cursor: pointer;
	margin-left: auto;
	margin-bottom: 10px;
}

.timeScheduleContainer {
	color: #999999;
	display: flex;
	padding-block: 9px;
	font-size: 12px;
	margin-bottom: 5px;
}

.subTitle {
	display: flex;
	align-items: center;
	gap: 12px;
}
